@font-face {
    font-family: 'Careem';
    src: url(./assets/fonts/CAREEM.otf);
  }
  
 * {
    font-family: 'Careem';
  }

.navbarnew {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 80px;
    background-color: #332a3d;
}

.linkNavNew {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.linkNavNew li {
    margin: 0px 20px;
    color: #fff;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkNavNew a {
    color: #fff;
}

.activeNavNew {
    border-bottom: #fff solid 2px;
}
.activeNavNewH {
    border-bottom: #fff solid 2px;
}
.continerLabelReg{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.leftNewNav{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.rightNewNav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.btnLOGANDREg{
    height: 30px;
    width: 120px;
    background-color: #f33050;
    color: #fff;
    outline: none;
    font-size: 14px;
    font-weight: 300;
    border-radius: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 0px 10px; 
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
   
}
.imageAvaContiner{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

@media (max-width: 768px) {
    .leftNewNav{
        flex-direction: column;
    }
    .navbarnew {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 80px;
        background-color: #332a3d;
    }
}

#infoShipPdfPage{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #AppBarPageContiner{
    height: 60px;
    width: 100%;
    background-color: #054cb0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0px 30px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  #textDescPageContinerAdmin {
    text-align: right;
    font-size: 18px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 10px;
    border-radius: 10px;
    min-height: 300px;
    margin-right: 10px;
    margin-left: 10px;
  }
  #textDescPageContiner1 {
    display: flex;
    margin: 10px;
  }
  #textDescPagelabel {
    width: 30%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fe393e;
    color: #fff;
    border-radius: 0 5px 5px 0;
  }
  #textDescPagecontent {
    width: 70%;
    min-height: 40px;
    /* direction: ltr; */
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    padding-right: 30px;
    padding-left: 30px;
    background-color: #eee;
    border-radius: 5px 0 0 5px;
  }
  #textDescPageContinerImgAdmin {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 10px;
    border-radius: 10px;
    min-height: 280px;
  }
  #PMbUtton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    background-color: #000000;
    cursor: pointer;
    margin: 0px 5px;
  
  }
  #PMbUtton:active{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
#BTNPrInt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    background-color: #2688d8;
    cursor: pointer;
}
#inputAdd2Continer {
    /* direction: rtl; */
    padding: 30px;
    background-color: #332a3d;
}
#ifmcontentstoprintshop{
    display: none;
  /* font-size: 50px;
  font-weight: 600px; */
    height:auto;
    width: 5.06in;
  }
  @media print{@page {size: landscape}}
#colInputSh {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

#ContinerInPut {
    display: flex;
    justify-content: flex-start;
    margin: 20px 30px;

}

#colInputSh label {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;

}

#InputTExtDash111 {
    width: 260px;
    height: 40px;
    border: 0px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    /* background-color: #fafafa; */
}

.InputTExtDash111 {
    width: 300px;
    height: 40px;
    border: 0px;
    color: #fff;
    border-bottom: #fff solid 2px;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #332a3d;
}

#NavContinerApp {
    position: absolute;
    left: 0px;
  
  }
  .DataTableContiner {
    direction: ltr;
  }
  .classesroot {
    direction: rtl;
    background-color: #C1A269;
  }
  .wating {
    background-color: #F8C471;
    color: #fff;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .done {
    background-color: #76D7C4;
    color: #fff;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cancel {
    background-color: #dd6656;
    color: #fff;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
input,
select
{
    font-family: 'Careem';
    padding: 0;
    margin: 0;
    outline: none;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    color: #444;
}

.main-block1 {
    background: url("./assets/images/header.jpg") no-repeat center;
    background-size: cover;
}

h1,
h2 {
    font-family: 'Careem';
    text-transform: uppercase;
    font-weight: 400;
}

h2 {
    margin: 0 0 0 8px;
}

.main-block {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
  
    padding: 25px;
    background: rgba(0, 0, 0, 0.5);
}



.left-part {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fa-graduation-cap {
    font-size: 72px;
}

#form ,form{
    background: #e6e0e0b3;
    padding: 25px;
    direction: rtl;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    width: 95px;
    height: 40px;
}
.css-5ljhhe, [data-css-5ljhhe] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 205px;
    height: 40px;
    background-color: white;
    box-shadow: inset 0 0 0 1px rgb(67 90 111 / 30%), inset 0 1px 2px rgb(67 90 111 / 14%);
}
.title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.info {
    display: flex;
    flex-direction: column;
}

input,
select,#PIk {
 text-align: right;
    padding: 5px;
    width: 300px;
    margin-bottom: 30px;
    
    border: 1px solid #000;
    border-radius: 4px;
}

input::placeholder {
    color: #444;
}

option:focus {
    border: none;
}

option {
    background: rgb(255, 255, 255);
    border: none;
}

.checkbox input {
    margin: 0 10px 0 0;
    vertical-align: middle;
}

.checkbox a {
    color: #d32c2c;
    ;
}

.checkbox a:hover {
    color: #fff;
}

.btn-item,
button {
    padding: 10px 5px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    background: #d32c2c;
    ;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.btn-item {
    display: inline-block;
    margin: 20px 5px 0;
}

button {
    width: 100%;
}

button:hover,
.btn-item:hover {
    background: #fff;
    color: #d32c2c;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background-color: #d32c2c;
    border-color: #d32c2c;
}
#continerProfile{

    width: 96%;
    min-height: 300px;
    border-radius: 15px;
    margin: 20px 2%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
}
#continerProfile1{
    display: flex;
    align-items: center;
    justify-content: center;
}
#continerProfile2{

    width: 96%;
    min-height: 300px;
    border-radius: 15px;
    margin: 20px 2%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#proPhoto{
 
    width: 150px;
    height: 150px;
}

._2oSbenaQCQZPXOD75Sj6nE {
    overflow: hidden;
    position: relative;
    border: 2px solid #f12a47;
    margin: 0 auto;
}
._1Fq_tZ_l7SwH_r_a_SNOtA .GNSzzq7thIGJvQKQMnUF4 {
    height: 40px;
    width: 171px;
}
._1MKAF96uXJHSW-a1nWoriQ {
    position: relative;
    width: 115px;
    margin: 10px auto;
}

._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B {
    background-color: rgb(218, 107, 125);
    /* text-align: center; */
    height: 20px;
    width: 20px;
    /* padding: 0px; */
    /* margin-left: 10px; */
}
._1Fq_tZ_l7SwH_r_a_SNOtA ._1YelFC_KTV9aLijs9ogXcQ {
    /* background: #c7232300; */
    /* border: 0; */
    margin: 0px;
    height: 20px;
    width: 20px;
    padding: 0px;
    display: flex;
    /* cursor: pointer; */
}
._6IMSWtd3wtGGn2KgOcZIK {
    position: absolute;
    width: 146px;
    left: 5px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}
#ContinerInPut button{
    width: 100px;
}
#colProf{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
}
#gameContinerFild{
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #d32c2c;
    background-color: #fff;
    position: relative;
    cursor: pointer;
}
#gameContinerFild:hover{
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}
#gammmeMainContiner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#colgame{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
}
#ContinerInPut{
    display: flex;
    justify-content: flex-start;
    margin: 20px 30px;
  
  }
  #ContinerInPut label{
    height: 40px;
    display: flex;
    align-items: center;
    width: 33%;
  color: #fff;
  }
  #InputTExtDash111{
    width: 260px;
    height: 40px;
    border-radius: 5px;
    padding-right: 10px;
    border: #fff solid 2px;
    background-color: #fff;
    color:#000;
  }
  #InputTExtDash111Image{
    width: 260px;
    height: 33px;
    border: 0px;
   border-radius: 5px;
    border: #fff solid 2px;
  
  }
  #DownloadExcelBtnContiner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
    cursor: pointer;
  }
  
  #iconTableContiner {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#fff;
    box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  #iconTableContiner1 {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#fff;
    box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    right: 40px;
  }
  #textAreaAddUser{
    width: 260px;
    min-height: 60px;
    max-height: 200px;
    border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    border-radius: 5px;
    padding-right: 20px;
    border: #fff solid 2px;
  }

#BtnAddUser{
cursor: pointer;
margin: 30px;
border-radius: 10px;
}
#btnAddContiner{
    display: flex;
    align-items: center;
    justify-content: center;
}
#gameContinerFild:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#gameContinerFild1{
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #d32c2c;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
@media (min-width: 568px) {

    html,
    body {
        height: 100vh;
    }

    .main-block {
        flex-direction: row;
        min-height: 100vh;
    }

    form {
        flex: 1;
        min-height: auto;
        max-height: auto;
    }

   
}